import { serializeUserSurgeryRequestContent, serializeUserSurgeryInterestContent, UserSurgeryRequestContent, UserSurgeryInterestContent } from "@imago/model"
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"
import { FatUserQuery } from "./user.ts"
import { api } from "./_imago_api.ts"

const SurgeryInterestQuery = {
	use() {
		return {data: FatUserQuery.use().data?.userSurgeryInterest}
	},
	setData(qc: QueryClient, k: [], userSurgeryInterest: UserSurgeryInterestContent) {
		FatUserQuery.patchData(qc, [], old => ({
			...old!,
			userSurgeryInterest,
		}))
	},
	async invalidate(qc: QueryClient, k: []) {
		await FatUserQuery.invalidate(qc, [])
	},
}

export function useSurgeryInterest() {
	return SurgeryInterestQuery.use().data
}

export function useSetSurgeryInterest() {
	const qc = useQueryClient()

	const mutation = useMutation({
		mutationFn: async (surgeryInterest: UserSurgeryInterestContent) => {
			try {
				await api.put('user-surgery-interest/', {
					json: serializeUserSurgeryInterestContent(surgeryInterest),
				})
				SurgeryInterestQuery.setData(qc, [], surgeryInterest)
			} catch (e) {
				SurgeryInterestQuery.invalidate(qc, [])
				throw e
			}
		}
	})
	return mutation
}

export function useSubmitSurgeryRequest() {
	const mutation = useMutation({
		mutationFn: async (surgeryRequest: UserSurgeryRequestContent) => {
			await api.put('user-surgery-request/', {
				json: serializeUserSurgeryRequestContent(surgeryRequest),
			})
		}
	})
	return mutation
}

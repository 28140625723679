import { deserializeSubscriptionInfo, SubscriptionInfo } from "@imago/model"
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"
import { FatUserQuery } from "./user.ts"
import { api } from "./_imago_api.ts"

const SubscriptionQuery = {
	use() {
		return {data: FatUserQuery.use().data?.subscription}
	},
	setData(qc: QueryClient, k: [], subscription: SubscriptionInfo) {
		FatUserQuery.patchData(qc, [], old => ({
			...old!,
			subscription,
		}))
	},
	async invalidate(qc: QueryClient, k: []) {
		await FatUserQuery.invalidate(qc, [])
	},
}

export function useSubscription() {
	return SubscriptionQuery.use().data
}

export function useUnsubscribe_internal() {
	const qc = useQueryClient()

	const mutation = useMutation({
		mutationFn: async () => {
			try {
				const data = deserializeSubscriptionInfo(await api.delete('subscription/').json())
				SubscriptionQuery.setData(qc, [], data)
			} catch (e) {
				SubscriptionQuery.invalidate(qc, [])
				throw e
			}
		}
	})
	return mutation
}

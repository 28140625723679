import { queryOptions, useQueries, useQuery, QueryClient } from "@tanstack/react-query"

export class Query<Key extends readonly unknown[], FlatKey extends string[], Ret> {
	constructor(
		private flatKeyFn: (...t: Key) => FlatKey,
		private fetchFn: () => (...key: FlatKey) => Promise<Ret>
	) {}

	flatKey(...t: Key) {
		return this.flatKeyFn(...t)
	}

	private useQueryOptions() {
		const queryFn = this.fetchFn()
		return (t: Key) => queryOptions({
			staleTime: Infinity,
			queryKey: this.flatKeyFn(...t),
			queryFn: ({queryKey}) => queryFn(...queryKey),
		})
	}

	use(...t: Key) {
		const options = this.useQueryOptions()
		return useQuery(options(t))
	}

	useMany(ts: Key[]) {
		const options = this.useQueryOptions()
		return useQueries({queries: ts.map(t => options(t))})
	}

	noUse() {
		this.useQueryOptions()
		return useQuery({
			staleTime: Infinity,
			queryKey: ['nouse'],
			queryFn: () => null,
		})
	}

	getData(qc: QueryClient, t: Key) {
		return qc.getQueryData<Ret>(this.flatKey(...t))
	}

	setData(qc: QueryClient, t: Key, data: Ret) {
		qc.setQueryData<Ret>(this.flatKey(...t), data)
	}

	patchData(qc: QueryClient, t: Key, updater: (oldData: Ret) => Ret) {
		const queryKey = this.flatKey(...t)

		const state = qc.getQueryState<Ret>(queryKey)
		if (!state || !state.data) {
			console.warn('Can`t patch a query data that`s not there.')
			qc.invalidateQueries({queryKey})
			return
		}
		qc.setQueryData<Ret>(queryKey, updater(state.data), {
			updatedAt: state.dataUpdatedAt
		})
	}

	async invalidate(qc: QueryClient, t: Key) {
		await qc.invalidateQueries({queryKey: this.flatKey(...t)})
	}
}

import ky from "ky"

export const API_BASE = process.env.BACKEND_URI ?? 'http://localhost:8080'

export const api = ky.create({
	prefixUrl: API_BASE,
	timeout: false,
	hooks: {
		beforeRequest: [
			request => {
				const token = localStorage.getItem('access_token')
				if (token) {
					request.headers.set('authorization', `Bearer ${token}`)
				}
			}
		]
	}
})

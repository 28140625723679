import { isProduct, isService, isServiceProduct, ProductID, ProductInfo, ServiceID, ServiceInfo, ServiceProductID, ServiceProductInfo, TEMPORARILY_HERE_productsAndServices } from "@imago/model"

export function useServices(): Map<ServiceID, ServiceInfo> | undefined {
	return new Map(TEMPORARILY_HERE_productsAndServices.filter(ps => isService(ps)).map(s => [s.ID, s]))
}

export function useProducts(): Map<ProductID, ProductInfo> | undefined {
	return new Map(TEMPORARILY_HERE_productsAndServices.filter(ps => isProduct(ps)).map(p => [p.ID, p]))
}

export function useServiceProducts(): Map<ServiceProductID, ServiceProductInfo> | undefined {
	return new Map(TEMPORARILY_HERE_productsAndServices.filter(ps => isServiceProduct(ps)).map(s => [s.ID, s]))
}

function getById<K, V>(map: Map<K, V> | undefined, k: K | undefined, error: () => Error): V | undefined {
	if (map === undefined || k === undefined) {
		return undefined
	}

	const v = map.get(k)
	if (v === undefined) {
		throw error()
	}

	return v
}

export function useService(ID: ServiceID | undefined): ServiceInfo | undefined {
	return getById(useServices(), ID, () => new Error('Invalid service ID.'))
}

export function useProduct(ID: ProductID | undefined): ProductInfo | undefined {
	return getById(useProducts(), ID, () => new Error('Invalid product ID.'))
}

export function useServiceProduct(ID: ServiceProductID | undefined): ServiceProductInfo | undefined {
	return getById(useServiceProducts(), ID, () => new Error('Invalid service product ID.'))
}

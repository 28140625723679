import { ASSISTANT_CHECK_STATUSES, CALL_STATUSES, deserializeEMR, EMRID, EMRInfo, EMRKindID, GAHTAssistantCheck_WithPreCallSummary_ID, GAHTBloodResults_ID, GAHTFHT_ID, GAHTFHTConsent_ID, GAHTIntake_ID, GAHTMHT_ID, GAHTMHTConsent_ID, GAHTSetupCall_WithPrescriptionDraft_ID, Migrated_ID, MimeType, PrescriptionContent, PrescriptionDocumentContent, PrescriptionRequestContent, ReportContent, TherapyID, WelcomeCall_WithWelcomed_ID, Welcomed_ID } from "@imago/model"
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"
import { Temporal } from "@imago/api-client"
import { TF_FormID } from "@imago/api-client-typeform"
import { serialize } from "@deepkit/type"
import { FatUserQuery } from "./user.ts"
import { api } from "./_imago_api.ts"
import { useConfig } from "../api.ts"

const EMRsQuery = {
	use() {
		return {data: FatUserQuery.use().data?.emrs}
	},
	add(qc: QueryClient, result: EMRInfo) {
		FatUserQuery.patchData(qc, [], old => ({
			...old!,
			emrs: [
				result,
				...old!.emrs || [],
			],
		}))
	},
	fake(qc: QueryClient, kindID: EMRKindID) {
		this.add(qc, {
			ID: 'dummy' as EMRID,
			timestamp: Temporal.Now.instant(),
			kindID,
			mimeType: 'whatever' as MimeType,
			callStatus: CALL_STATUSES.get(kindID),
			assistantCheckStatus: ASSISTANT_CHECK_STATUSES.get(kindID),
			hasWelcomed: kindID === WelcomeCall_WithWelcomed_ID,
			hasPreCallSummary: kindID === GAHTAssistantCheck_WithPreCallSummary_ID,
			hasPrescriptionDraft: kindID === GAHTSetupCall_WithPrescriptionDraft_ID,
		})
	},
	async invalidate(qc: QueryClient, t: []) {
		await FatUserQuery.invalidate(qc, [])
	},
}

function lastEMROfKind(kind: EMRKindID) {
	const emrs = EMRsQuery.use().data
	if (emrs === undefined) return
	return emrs.filter(emr => emr.kindID === kind)[0] ?? null
}

export function useWelcomed(): boolean | undefined {
	const lastWelcomed = lastEMROfKind(Welcomed_ID)
	if (lastWelcomed === undefined) return
	return lastWelcomed ? true : false
}

export function useMigrated(): boolean | undefined {
	const lastMigrated = lastEMROfKind(Migrated_ID)
	if (lastMigrated === undefined) return
	return lastMigrated ? true : false
}

export function useLastGAHTBloodResults(): Temporal.Instant | null | undefined {
	const lastResults = lastEMROfKind(GAHTBloodResults_ID)
	if (lastResults === undefined) return
	return lastResults ? lastResults.timestamp : null
}

export function useGAHTIntaken(): boolean | undefined {
	const lastIntake = lastEMROfKind(GAHTIntake_ID)
	if (lastIntake === undefined) return
	return lastIntake ? true : false
}

export function useGAHTFHTConsented(): boolean | undefined {
	const lastIntake = lastEMROfKind(GAHTFHTConsent_ID)
	if (lastIntake === undefined) return
	return lastIntake ? true : false
}

export function useGAHTMHTConsented(): boolean | undefined {
	const lastIntake = lastEMROfKind(GAHTMHTConsent_ID)
	if (lastIntake === undefined) return
	return lastIntake ? true : false
}

export interface PrescriptionInfo extends PrescriptionContent {
	basedOn?: Temporal.Instant

	requestedOn?: Temporal.Instant
	requestersComment?: string
	requestersAddress?: string

	issuedOn?: Temporal.Instant
	receivedOn?: Temporal.Instant
	runsOutOn?: Temporal.PlainDate
}

export type RequestedPrescriptionInfo = PrescriptionInfo & {
	requestedOn: Temporal.Instant
	requestersComment: string
	requestersAddress: string
}

export type IssuedPrescriptionInfo = PrescriptionInfo & {
	issuedOn: Temporal.Instant
}

export type ReceivedPrescriptionInfo = IssuedPrescriptionInfo & {
	receivedOn: Temporal.Instant
	runsOutOn: Temporal.Instant
}

export function useGAHTPrescriptions(): (RequestedPrescriptionInfo | IssuedPrescriptionInfo)[] | undefined {
	// Disabled for now, needs rework.
	const prescriptionRequests = [] as Array<EMRInfo & {prescriptionRequest: PrescriptionRequestContent}>
	const prescriptionDocuments = [] as Array<EMRInfo & {prescriptionDocument: PrescriptionDocumentContent}>

	const ret = [
		...prescriptionRequests.map((emr): RequestedPrescriptionInfo => {
			const pr = emr.prescriptionRequest!
			return {
				therapy: pr.draft.therapy,
				advice: pr.draft.advice,
				items: pr.draft.items,
				reviewIn: pr.draft.reviewIn,
				requestedOn: emr.timestamp,
				requestersComment: pr.comment,
				requestersAddress: pr.mailingAddress,
			}
		}),
		...prescriptionDocuments.map((emr): IssuedPrescriptionInfo => {
			const pd = emr.prescriptionDocument!
			return {
				therapy: pd.prescription.therapy,
				advice: pd.prescription.advice,
				items: pd.prescription.items,
				reviewIn: pd.prescription.reviewIn,
				requestersAddress: pd.mailingAddress,
				issuedOn: emr.timestamp,
			}
		}),
	]
	return ret
}

export function useGAHTDraftPrescription(): PrescriptionInfo | null | undefined {
	// Disabled for now, needs rework.
	const lastPrescriptionLike = undefined as undefined | (EMRInfo & {report: ReportContent, prescriptionRequest: PrescriptionRequestContent, prescriptionDocument: PrescriptionDocumentContent})

	if (!lastPrescriptionLike) return null

	if (lastPrescriptionLike.report?.prescriptionDraft)
		return lastPrescriptionLike.report?.prescriptionDraft

	if (lastPrescriptionLike.prescriptionRequest)
		return null

	if (lastPrescriptionLike.prescriptionDocument)
		return {
			basedOn: lastPrescriptionLike.timestamp,
			therapy: lastPrescriptionLike.prescriptionDocument.prescription.therapy,
			advice: lastPrescriptionLike.prescriptionDocument.prescription.advice,
			items: lastPrescriptionLike.prescriptionDocument.prescription.items,
			reviewIn: lastPrescriptionLike.prescriptionDocument.prescription.reviewIn,
			requestersAddress: lastPrescriptionLike.prescriptionDocument.mailingAddress,
		}
}

export function useGAHTTherapy(): TherapyID | null | undefined {
	const draft = useGAHTDraftPrescription()
	const prescriptions = useGAHTPrescriptions()
	if (draft === undefined || prescriptions === undefined) return undefined
	return draft ? draft.therapy : prescriptions[0] !== undefined ? prescriptions[0].therapy : null
}

export function useGAHTConsented() {
	const therapy = useGAHTTherapy()
	const fht = useGAHTFHTConsented()
	const mht = useGAHTMHTConsented()
	if (therapy === undefined) return
	if (therapy === GAHTFHT_ID) return fht
	if (therapy === GAHTMHT_ID) return mht

	// TODO remove
	if (therapy === null) {
		if (fht === undefined || mht === undefined) return
		return fht || mht
	}

	return false
}

export function useTfFile_internal() {
	const qc = useQueryClient()

	const mutation = useMutation({
		mutationFn: async ({form, response}: {form: TF_FormID, response: string}) => {
			try {
				const emr = deserializeEMR(await api.post('typeform/', {
					json: {
						form,
						response,
					},
				}).json())

				EMRsQuery.add(qc, emr)
			} catch (e) {
				EMRsQuery.invalidate(qc, [])
				throw e
			}
		}
	})

	return mutation
}

export function useSubmitGAHTBloodResults() {
	const qc = useQueryClient()

	const mutation = useMutation({
		mutationFn: async ({files}: {
			files: File[]
		}) => {
			try {
				const data = new FormData()
				let i = 0
				for (const file of files) {
					data.append(`file${i}`, file)
					i += 1
				}
				const result = deserializeEMR(await api.post('gaht/blood-results/', {
					body: data,
				}).json())

				EMRsQuery.add(qc, result)
			} finally {
				EMRsQuery.invalidate(qc, [])
			}
		}
	})
	return mutation
}

export function useFakeSubmitGAHTBloodResults() {
	const config = useConfig()
	const qc = useQueryClient()
	const mutation = {
		mutate: () => EMRsQuery.fake(qc, GAHTBloodResults_ID),
		isPending: false,
	}
	return config.testMode ? mutation : null
}

export function useRequestGAHTPrescription() {
	const qc = useQueryClient()

	const mutation = useMutation({
		mutationFn: async ({draft, comment, mailingAddress}: {
			draft: PrescriptionContent | null,
			comment: string
			mailingAddress: string
		}) => {
			try {
				const result = deserializeEMR(await api.post('gaht/prescription-requests/', {
					json: serialize<PrescriptionRequestContent>({draft: draft ?? {
						therapy: undefined,
						advice: '',
						items: [],
						reviewIn: Temporal.Duration.from({months: 3}).toString(),
					}, comment, mailingAddress}),
				}).json())

				EMRsQuery.add(qc, result)
			} catch (e) {
				EMRsQuery.invalidate(qc, [])
				throw e
			}
		}
	})
	return mutation
}

export function useTESTSetWelcomed() {
	const config = useConfig()
	const qc = useQueryClient()

	const mutation = useMutation({
		mutationFn: async () => {
			try {
				const result = deserializeEMR(await api.put('TEST/welcomed/').json())

				EMRsQuery.add(qc, result)
			} catch (e) {
				EMRsQuery.invalidate(qc, [])
				throw e
			}
		}
	})
	return config.testMode ? mutation : undefined
}

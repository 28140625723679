import { GAHTFHT_ID, GAHTMHT_ID } from "@imago/model"
import { useGAHTTherapy } from "../../../api.ts"
import { useGAHTConsented, useMHTConsent, useFHTConsent } from "../../FormDialog.tsx"
export { useGAHTConsented as useConsented }

function _Consent() {
	const therapy = useGAHTTherapy()

	const fhtConsent = useFHTConsent()
	const mhtConsent = useMHTConsent()

	const isPending = fhtConsent.isPending || mhtConsent.isPending

	return <form onSubmit={ev => {
		ev.stopPropagation()
		ev.preventDefault()
		if (therapy === GAHTFHT_ID)
			fhtConsent.mutate()
		else if (therapy === GAHTMHT_ID)
			mhtConsent.mutate()
		else
			throw new Error("No therapy proposed, cannot get consent.")
	}}>
		<p>The informed consent model of care respects your fundamental human right to self-determination and bodily autonomy.</p>
		<p>To start the therapy - fill out our consent form.</p>
		<div className="row">
			{therapy ? <>
				<button type="submit" disabled={isPending}>Fill out the consent form</button>
			</> : <>
				<button type="submit" disabled={isPending} onClick={ev => {
					ev.stopPropagation()
					ev.preventDefault()
					fhtConsent.mutate()
				}}>Feminizing HT consent form</button>
				<button type="submit" disabled={isPending} onClick={ev => {
					ev.stopPropagation()
					ev.preventDefault()
					mhtConsent.mutate()
				}}>Masculinizing HT consent form</button>
			</>}
		</div>
	</form>
}

export function Consent() {
	const done = useGAHTConsented()
	return <section data-done={done ? true : undefined}>
		<h2>Give us your consent</h2>
		{!done ? <_Consent/> : <></>}
	</section>
}

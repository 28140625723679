import { AppointmentInfo, Intent, ServiceID, ServiceInfo, WelcomeCall_ID } from "@imago/model"
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FatUserQuery } from "./user.ts"
import { useConfig } from "../api.ts"
import { useWelcomed } from "./user-emrs.ts"
import { YCBM_Intents_cancel, YCBM_Intents_confirm, YCBM_Intents_getAvailability, YCBM_Intents_getAvailabilityKey, YCBM_Intents_selectSlot, YCBM_Intents_selectTeamMember, YCBMRemote } from "@imago/api-client-ycbm"
import { Temporal, URIObject } from "@imago/api-client"

const writeToYCBM = true
const ycbm = new YCBMRemote(URIObject.parse('https://api.youcanbook.me'))

const AppointmentsQuery = {
	use() {
		const appointments = FatUserQuery.use().data?.appointments

		const config = useConfig()
		if (config.testMode) { // TODO remove
			const welcomed = useWelcomed()
			if (welcomed === true)
				return {data: appointments?.filter(app => app.serviceID != WelcomeCall_ID)}
		}
		return {data: appointments}
	},
	add(qc: QueryClient, result: AppointmentInfo) {
		FatUserQuery.patchData(qc, [], old => ({
			...old!,
			appointments: [
				...old!.appointments || [],
				result,
			]
		}))
	},
	remove(qc: QueryClient, cached: AppointmentInfo) {
		FatUserQuery.patchData(qc, [], old => ({
			...old!,
			appointments: old!.appointments!.filter(app => app.ycbm_secret != cached.ycbm_secret)
		}))
	},
	async invalidate(qc: QueryClient, t: []) {
		await FatUserQuery.invalidate(qc, [])
	},
}

export function useAppointments() {
	return AppointmentsQuery.use().data
}

export function useServiceAppointment(serviceID?: ServiceID) {
	const appointments = useAppointments()
	if (serviceID === undefined || appointments === undefined) return
	const filtered = appointments.filter(app => app.serviceID === serviceID)
	return filtered.length > 0 ? filtered[0] : null
}

export function useBookableSlots(intent: Intent | undefined) {
	const ycbmIntentID = intent?.ycbm_secret

	return useQuery({
		queryKey: ['bookable-slots', ycbmIntentID],
		queryFn: async (): Promise<Temporal.ZonedDateTime[]> => {
			if (!intent || !ycbmIntentID) {
				return []
			}
			const data = await YCBM_Intents_getAvailabilityKey(ycbm, ycbmIntentID)
			const data2 = await YCBM_Intents_getAvailability(ycbm, data.key)
			return data2.slots.map(slot => Temporal.Instant.fromEpochMilliseconds(parseInt(slot.startsAt)).toZonedDateTimeISO(Temporal.Now.timeZoneId()))
		}
	})
}

export function useBookMutation({service, intent}: {
	service: ServiceInfo
	intent?: Intent
}) {
	const ycbmIntentID = intent?.ycbm_secret
	const isIntentBooked = useServiceAppointment(service.ID)

	const qc = useQueryClient()
	const mutation = useMutation({
		mutationFn: async ({slot}: {
			slot: Temporal.ZonedDateTime
		}) => {
			try {
				if (writeToYCBM) {
					await YCBM_Intents_selectSlot(ycbm, ycbmIntentID!, {
						startsAt: slot.toInstant().epochMilliseconds,
						timeZone: slot.timeZoneId,
					})
					await YCBM_Intents_selectTeamMember(ycbm, ycbmIntentID!, {
						teamMemberId: "AUTO",
					})
					await YCBM_Intents_confirm(ycbm, ycbmIntentID!)
				}

				const appointment: AppointmentInfo = {
					serviceID: service.ID,
					duration: service.duration,
					start: slot,
					end: slot.add(service.duration),
					ycbm_secret: intent?.ycbm_secret
					// TODO googleMeetLink
				}
				AppointmentsQuery.add(qc, appointment)
				return appointment
			} finally {
				AppointmentsQuery.invalidate(qc, [])
			}
		}
	})
	return ycbmIntentID && !isIntentBooked ? mutation : undefined
}

export function useCancelBookingMutation({appointment}: {
	appointment: AppointmentInfo | undefined
}) {
	const ycbmIntentID = appointment?.ycbm_secret
	const isIntentBooked = useServiceAppointment(appointment?.serviceID)

	const qc = useQueryClient()
	const mutation = useMutation({
		mutationFn: async () => {
			try {
				if (writeToYCBM) {
					await YCBM_Intents_cancel(ycbm, ycbmIntentID!)
				}

				AppointmentsQuery.remove(qc, appointment!)
			} finally {
				AppointmentsQuery.invalidate(qc, [])
			}
		}
	})

	return ycbmIntentID && isIntentBooked ? mutation : undefined
}

import { TestUserTemplateID } from "@imago/model"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { api, API_BASE } from "./_imago_api.ts"
import { FatUserQuery } from "./user.ts"
import { useConfig } from "./app-config.ts"

export function useSendSignInMessage(onSuccess: ({email}: {email: string}) => void) {
	return useMutation({
		async mutationFn({email}: {email: string}) {
			const nonce = crypto.randomUUID()
			localStorage.setItem('nonces', nonce)
			await api.post('auth/by-email/', {
				json: {
					email,
					nonce,
				}
			})
			return {email}
		},
		onSuccess,
	})
}

export function useSignInAsATestUser() {
	const config = useConfig()
	const mutation = useMutation({
		async mutationFn({templateID}: {templateID: TestUserTemplateID}) {
			localStorage.setItem('nonces', 'test')
			window.location.href = `${API_BASE}/auth/sign-in-as-a-test-user/?templateID=${templateID}&nonce=test`
		}
	})
	return config.testMode ? mutation : null
}

export function useSignedIn(): boolean | undefined {
	const fatUser = FatUserQuery.use().data
	if (fatUser === undefined) return
	return fatUser ? true : false
}

export function useSignOut() {
	const qc = useQueryClient()

	return useMutation({
		mutationFn: async () => {
			await api.delete('auth/session/')
			localStorage.removeItem('access_token')
			localStorage.removeItem('nonces')
			FatUserQuery.setData(qc, [], null)
		}
	})
}

import { ProductID } from "@imago/model"
import { FatUserQuery } from "./user.ts"

const InventoryQuery = {
	use() {
		return {data: FatUserQuery.use().data?.inventory}
	},
}

function useInventory() {
	return InventoryQuery.use().data
}

export function useOwns(productID: ProductID) {
	const inventory = useInventory()
	return inventory?.includes(productID)
}

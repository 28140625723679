import { Intent, IntentRequest } from "@imago/model"
import { useMutation } from "@tanstack/react-query"
import { useEffect } from "react"
import { serialize, deserialize } from "@deepkit/type"
import { api } from "./_imago_api.ts"

export async function declareIntent(request: IntentRequest): Promise<Intent> {
	const raw = await api.post('intents/', {
		json: serialize<IntentRequest>(request),
	}).json()
	return deserialize<Intent>(raw)
}

export function useIntent(request: IntentRequest): Intent | undefined {
	// Somewhat deprecated. Try to use declareIntent instead.
	const mut = useMutation({
		mutationFn: async () => {
			console.debug('useIntent', request)
			return await declareIntent(request)
		},
	})
	useEffect(() => {
		mut.mutate()
	}, [])
	return mut.data
}

import { deserializeFatUser, FatUser } from "@imago/model"
import { HTTPError } from "ky"
import { Query } from "@imago/api-client-for-react"
import { api } from "./_imago_api.ts"

export const FatUserQuery = new Query(
	() => ['fat-user'] as const,
	() => {
		return async (_) => {
			let patches = new Array<FatUser>()

			const hashParams = new URLSearchParams(document.location.hash.substring(1))
			const searchParams = new URLSearchParams(document.location.search.substring(1))

			const idToken = hashParams.get('id_token')
			const nonces = localStorage.getItem('nonces') || ''

			if (idToken) {
				try {
					const {access_token: accessToken} = await api.put('auth/session/', {
						json: {
							id_token: idToken,
							nonces
						},
					}).json<{access_token: string}>()
					localStorage.setItem('access_token', accessToken)
				} catch (e) {
					try {
						if (e instanceof HTTPError) {
							console.error('Sign-in failed.', (await e.response.json()).message)
						} else {
							console.error('Sign-in failed.', e)
						}
					} catch (ee) {
						console.error('Sign-in failed.', e)
						console.warn(ee)
					}
				}
			}

			const setupIntent = searchParams.get('setup_intent')
			const paymentIntent = searchParams.get('payment_intent')
			const redirectStatus = searchParams.get('redirect_status')

			if ((setupIntent ?? paymentIntent) && redirectStatus === 'succeeded') {
				const patch = deserializeFatUser(await api.post('stripe/', {
					json: {
						intent: setupIntent ?? paymentIntent
					},
				}).json())
				patches.push(patch)
			}
			searchParams.delete('setup_intent')
			searchParams.delete('payment_intent')
			searchParams.delete('redirect_status')

			const url = new URL(document.location.href)
			url.search = searchParams.toString()
			url.hash = hashParams.toString()
			if (document.location.href != url.toString()) {
				window.history.replaceState(null, '', url)
			}

			let data
			try {
				data = deserializeFatUser(await api.get('').json())
			} catch (e) {
				if (e instanceof HTTPError) {
					if (e.response.status === 401) {
						return null
					}
				}
				throw e
			}
			for (const patch of patches) {
				data = {...data, ...patch}
			}
			if (data) {
				const url = new URL(document.location.href)
				url.search = ''
				url.hash = ''
				if (document.location.href != url.toString()) {
					window.history.replaceState(null, '', url)
				}
			}
			return data
		}
	}
)

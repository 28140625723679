import { UserProfileContent } from "@imago/model"
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"
import { serialize } from "@deepkit/type"
import { FatUserQuery } from "./user.ts"
import { api } from "./_imago_api.ts"

const ProfileQuery = {
	use() {
		return {data: FatUserQuery.use().data?.userProfile}
	},
	setData(qc: QueryClient, k: [], userProfile: UserProfileContent) {
		FatUserQuery.patchData(qc, [], old => ({
			...old!,
			userProfile,
		}))
	},
	async invalidate(qc: QueryClient, k: []) {
		await FatUserQuery.invalidate(qc, [])
	},
}

export function useProfile() {
	return ProfileQuery.use().data
}

export function useSetProfile() {
	const qc = useQueryClient()

	const mutation = useMutation({
		mutationFn: async (profile: UserProfileContent) => {
			try {
				await api.put('user-profile/', {
					json: serialize<UserProfileContent>(profile),
				})
				ProfileQuery.setData(qc, [], profile)
			} catch (e) {
				ProfileQuery.invalidate(qc, [])
				throw e
			}
		}
	})
	return mutation
}

import { useState } from "react"
import { Temporal } from "@imago/api-client"
import { useNow } from "../queries/Now.ts"
import { AppointmentInfo, Intent, ServiceID, ServiceInfo } from "@imago/model"
import { useBookableSlots, useBookMutation, useCancelBookingMutation, useIntent, useService } from "../api.ts"
import { CancelButton, createDialog, DialogProvider, HTMLDialogImplementation, useCancelDialog, useDialog } from "./Dialog.tsx"

const locale = 'en-GB'

function CalendarTable({weeks, selectedDay, availableDays, onDaySelected}: {
	weeks: (Temporal.PlainDate|undefined)[][]
	selectedDay: Temporal.PlainDate | undefined
	availableDays: Set<string>
	onDaySelected: (day: Temporal.PlainDate) => void
}) {
	return <table className="calendar">
		<thead>
			<tr>
				<th><span>M</span></th>
				<th><span>T</span></th>
				<th><span>W</span></th>
				<th><span>T</span></th>
				<th><span>F</span></th>
				<th><span>S</span></th>
				<th><span>S</span></th>
			</tr>
		</thead>
		<tbody>
			{weeks.map((week, index) =>
				<tr key={index}>
					{week.map((day, index) =>
						<td
							key={index}
							data-done={day && Temporal.PlainDate.compare(day, Temporal.Now.plainDateISO()) < 0 ? true : undefined}
							data-active={day && selectedDay && day.equals(selectedDay) ? true : undefined}
							data-today={day && day.equals(Temporal.Now.plainDateISO()) ? true : undefined}
							onClick={ev => {
									if (!day || Temporal.PlainDate.compare(day, Temporal.Now.plainDateISO()) < 0)
										return
									ev.stopPropagation()
									ev.preventDefault()
									onDaySelected(day)
								}}
						>
							<button
								disabled={!day || !availableDays.has(day.toString()) ? true : undefined}
							>
								{day?.day ?? <>&nbsp;</>}
							</button>
						</td>
					)}
				</tr>
			)}
		</tbody>
	</table>
}
function CalendarMonthView({month, selectedDay, availableDays, onMonthSelected, onDaySelected}: {
	month: Temporal.PlainYearMonth
	selectedDay: Temporal.PlainDate | undefined
	availableDays: Set<string>
	onMonthSelected: (month: Temporal.PlainYearMonth) => void
	onDaySelected: (day: Temporal.PlainDate) => void
}) {
	const firstDay = month.toPlainDate({day: 1})
	const lastDay = month.add({months: 1}).toPlainDate({day: 1}).subtract({days: 1})

	const weeks = Array<Array<Temporal.PlainDate | undefined>>()
	let week = Array<Temporal.PlainDate | undefined>()
	for (let i = 1; i < firstDay.dayOfWeek; ++i) {
		week.push(undefined)
	}
	for (let day = firstDay; Temporal.PlainDate.compare(day, lastDay) <= 0; day = day.add({days: 1})) {
		week.push(day)
		if (day.dayOfWeek == 7) {
			weeks.push(week)
			week = []
		}
	}
	for (let i = 7; i > lastDay.dayOfWeek; --i) {
		week.push(undefined)
	}
	if (week) {
		weeks.push(week)
	}

	return <div className="month">
		<hgroup>
			<h4>{month.toLocaleString(locale, { month: 'long', year: 'numeric', calendar: 'iso8601' })}</h4>
			<div role="toolbar">
				<button onClick={ev => {
					ev.stopPropagation()
					ev.preventDefault()
					onMonthSelected(month.subtract({months: 1}))
				}}>
					<span className="material-symbols-outlined">keyboard_arrow_up</span>
				</button>
				<button onClick={ev => {
					ev.stopPropagation()
					ev.preventDefault()
					onMonthSelected(month.add({months: 1}))
				}}>
					<span className="material-symbols-outlined">keyboard_arrow_down</span>
				</button>
			</div>
		</hgroup>
		<CalendarTable weeks={weeks} selectedDay={selectedDay} onDaySelected={onDaySelected} availableDays={availableDays}/>
	</div>
}

function CalendarDayView({day, availableDays, availableTimes, onDaySelected, onTimeSelected}: {
	day: Temporal.PlainDate
	availableDays: Set<string>
	availableTimes: Set<string>
	onDaySelected: (day: Temporal.PlainDate | undefined) => void
	onTimeSelected: (time: Temporal.PlainTime) => void
}) {
	const firstWeekDay = day.subtract({days: day.dayOfWeek - 1})
	const week = []
	for (let i = 0; i < 7; ++i) {
		week.push(firstWeekDay.add({days: i}))
	}
	return <>
		<div className="week">
			<CalendarTable weeks={[week]} selectedDay={day} onDaySelected={onDaySelected} availableDays={availableDays}/>
		</div>
		<hr/>
		<div className="day">
			<hgroup>
				<h3>{day.toLocaleString(locale, {month: "short", day: "numeric", weekday: "short"})}</h3>
				<div role="toolbar">
					<button onClick={ev => {
						ev.stopPropagation()
						ev.preventDefault()
						onDaySelected(undefined)
					}}>
						<span className="material-symbols-outlined">calendar_month</span>
					</button>
					<button
						disabled={Temporal.PlainDate.compare(day.subtract({days: 1}), Temporal.Now.plainDateISO()) < 0 ? true : undefined}
						onClick={ev => {
							ev.stopPropagation()
							ev.preventDefault()
							onDaySelected(day.subtract({days: 1}))
						}}
					>
						<span className="material-symbols-outlined">keyboard_arrow_left</span>
					</button>
					<button onClick={ev => {
						ev.stopPropagation()
						ev.preventDefault()
						onDaySelected(day.add({days: 1}))
					}}>
						<span className="material-symbols-outlined">keyboard_arrow_right</span>
					</button>
				</div>
			</hgroup>
			<div className="scrollable">
				<div className="radio-pool">
					{[...availableTimes].map(t => {
						const time = Temporal.PlainTime.from(t)
						return (
							<button
								key={t}
								onClick={ev => {
									ev.stopPropagation()
									ev.preventDefault()
									onTimeSelected(time)
								}}
							>
								{time.toLocaleString(locale, {timeStyle: 'short'})}
							</button>
						)
					})}
					{availableTimes.size ? <></> : <p>No free time slots on this day.</p>}
				</div>
			</div>
		</div>
	</>
}

function Calendar({intent, onSlotSelected}: {
	intent: Intent | undefined
	onSlotSelected: (start: Temporal.ZonedDateTime) => void
}) {
	const {data: bookableSlots} = useBookableSlots(intent)

	const [month, _setMonth] = useState(Temporal.Now.plainDateISO().toPlainYearMonth())
	const [day, _setDay] = useState<Temporal.PlainDate>()

	function setMonth(month: Temporal.PlainYearMonth) {
		_setMonth(month)
	}
	function setDay(day: Temporal.PlainDate | undefined) {
		_setDay(day)
		if (day)
			_setMonth(day.toPlainYearMonth())
	}
	function setTime(time: Temporal.PlainTime) {
		if (!day)
			throw new Error()
		onSlotSelected(day.toPlainDateTime(time).toZonedDateTime(Temporal.Now.timeZoneId()))
	}

	const availableSlots = bookableSlots ? [...Map.groupBy(bookableSlots, slot => slot.toPlainDate().toString())].map(([date, slots]) => {
		return {
			day: Temporal.PlainDate.from(date),
			slots: slots.map(slot => slot.toPlainTime()),
		}
	}) : []

	const availableDays = new Set(availableSlots.map(ad => ad.day.toString()))
	const availableTimes = new Set(day ? Array<string>().concat(...availableSlots.filter(ad => ad.day.equals(day)).map(ad => ad.slots.map(time => time.toString()))) : [])

	return <>
		{day
			? <CalendarDayView day={day} onDaySelected={setDay} availableDays={availableDays} availableTimes={availableTimes} onTimeSelected={setTime}/>
			: <CalendarMonthView month={month} selectedDay={undefined} onMonthSelected={setMonth} onDaySelected={setDay} availableDays={availableDays}/>
		}
	</>
}

export function AppointmentView({appointment, draft, service: serviceParam, showTips = false}: {
	appointment: AppointmentInfo | undefined
	service?: ServiceInfo | undefined
	draft?: boolean
	showTips?: boolean
}) {
	const service = useService(appointment?.serviceID) ?? serviceParam

	const cancel = useCancelBookingMutation({appointment})

	const now = useNow()

	const confirmed = appointment && draft !== true

	const reschedulable = confirmed && service !== undefined
		? Temporal.ZonedDateTime.compare(appointment.start.subtract(service.freezeTime), now) > 0
		: false

	const joinable = confirmed
		? Temporal.ZonedDateTime.compare(appointment.start.subtract({minutes: 30}), now) < 0
		: false

	return <>
	<article className="appointment">
		<h4>{service?.title}</h4>
		{appointment ? <>
			<dl>
				<dt>Date:</dt>
				<dd>{appointment.start.toLocaleString(locale, {dateStyle: "full"})}</dd>
			</dl>
			<dl>
				<dt>Time:</dt>
				<dd>
					{appointment.start.toLocaleString(locale, {hour: "numeric", minute: "numeric"})}
					{" - "}
					{appointment.end.toLocaleString(locale, {hour: "numeric", minute: "numeric"})}
					{" "}
					<small>({appointment.start.timeZoneId})</small>
				</dd>
			</dl>
		</> : <></>}
		<dl>
			<dt>Duration:</dt>
			<dd>{(appointment ?? service)!.duration.minutes} minutes</dd>
		</dl>
		<dl>
			<dt>App:</dt>
			<dd>Google Meet</dd>
		</dl>
		{confirmed ? <>
			<hr/>
			<div className="row" style={{justifyContent: "end"}}>
					<button
						onClick={ev => {
							ev.stopPropagation()
							ev.preventDefault()
							cancel!.mutate()
						}}
						disabled={!reschedulable || !cancel || cancel.isPending}
						title={!reschedulable ? `The call can only be rescheduled ${service?.freezeTime.hours} hours before or earlier.` : ''}
					>
						Reschedule / Cancel
					</button>
					<a
						href={joinable ? appointment.googleMeetLink : undefined}
						role="button"
						className="primary"
						// @ts-ignore
						aria-disabled={!joinable || !appointment.googleMeetLink}
						title={!joinable ? `The call is not ready yet. Please come back nearer the start time.` : ''}
					>
						Join call
					</a>
			</div>
		</> : <></>}
	</article>
	{showTips ? <>
		{service ? <p><small>The call can only be rescheduled or cancelled at&nbsp;least&nbsp;{service.freezeTime.hours}&nbsp;hours in advance.</small></p> : <></>}
		{confirmed ? <p><small>The join button becomes active half an hour before.</small></p> : <></>}
	</> : <></>}
	</>
}

function Book({serviceID}: {
	serviceID: ServiceID
}) {
	const service = useService(serviceID)
	const intent = useIntent({verb: 'book', serviceID})
	return service ? <_Book service={service} intent={intent}/> : <></>
}

function _Book({service, intent}: {
	service: ServiceInfo
	intent: Intent | undefined
}) {
	const cancelDialog = useCancelDialog()

	const [selectedSlot, setSelectedSlot] = useState<Temporal.ZonedDateTime>()

	const draftAppointment: AppointmentInfo | undefined = selectedSlot ? {
		serviceID: service.ID,
		duration: service.duration,
		start: selectedSlot,
		end: selectedSlot.add(service.duration),
	} : undefined

	const book = useBookMutation({service, intent})

	return <main>
		<section>
			<hgroup>
				<CancelButton>
					<span className="material-symbols-outlined">arrow_back</span>
				</CancelButton>
				{draftAppointment ? <h1>Confirm your booking</h1> : <h1>Book your call</h1>}
			</hgroup>
			<AppointmentView draft={true} appointment={draftAppointment} service={service} showTips={true}/>
			{draftAppointment ? <>
				<div className="row" style={{justifyContent: "end"}}>
					<button onClick={ev => {
						ev.stopPropagation()
						ev.preventDefault()
						setSelectedSlot(undefined)
					}}>Back</button>
					<button type="submit" onClick={ev => {
						ev.stopPropagation()
						ev.preventDefault()
						book!.mutate({slot: draftAppointment.start}, {
							onSuccess: () => {
								setSelectedSlot(undefined)
								if (cancelDialog) {
									cancelDialog()
								}
							}
						})
					}} disabled={!book || book.isPending}>Confirm</button>
				</div>
			</> : <></>}
		</section>
		{!draftAppointment ? <>
			<hr data-total/>
			<section>
				<h2>Choose a time that works for you</h2>
				<Calendar intent={intent} onSlotSelected={setSelectedSlot}/>
			</section>
		</> : <></>}
		<hr data-total/>
		<aside>
			<p><small>Time zone: {Temporal.Now.timeZoneId()} ({Temporal.Now.plainTimeISO().toLocaleString(locale, {timeStyle: 'short'})})</small></p>
		</aside>
	</main>
}

const BookingDialog = createDialog<{serviceID: ServiceID}>()

export function useBook() {
	return useDialog(BookingDialog)
}

export function BookingDialogProvider({children}: {children: any}) {
	return <DialogProvider dialog={BookingDialog} Implementation={HTMLDialogImplementation(BookingDialogContent, {fullScreen: true})} >
		{children}
	</DialogProvider>
}

function BookingDialogContent({serviceID}: {
	serviceID: ServiceID
}) {
	return serviceID ? <Book serviceID={serviceID}/> : <></>
}

import { UserSurgeryInterestContent } from "@imago/model"
import { useProfile, useSetSurgeryInterest, useSubmitSurgeryRequest, useSurgeryInterest } from "../../api.ts"
import { Hello } from "../Hello.tsx"
import { useState } from "react"

interface ProcedureGroup {
	name: string
	content: ProcedureSubGroup[]
}

interface ProcedureSubGroup {
	name: string
	content: Procedure[]
}

type Procedure = {
	name: string
	costPLN: PriceRange
}

type PriceRange = [number, number, string[]?]

const PLNperEUR = 4.176

const PROCEDURES: ProcedureGroup[] = [
	{
		name: "Consultations",
		content: [
			{
				name: "",
				content: [
					{name: "Surgical consultation", costPLN: [400, 400]},
					{name: "Anesthesiology consultation", costPLN: [300, 300]},
				]
			}
		]
	},
	{
		name: "Anesthesiology",
		content: [
			{
				name: "",
				content: [
					{name: "Intravenous anesthesia (up to 60 min)", costPLN: [1000, 1000]},
					{name: "Anesthesia for surgery (IV, general with intubation or laryngeal mask, spinal)", costPLN: [3000, Infinity]},
				]
			}
		]
	},
	{
		name: "Facial feminization - plastic surgery",
		content: [
			{
				name: "",
				content: [
					{name: "Deep Plane Face Lift", costPLN: [50000, Infinity]},
					{name: "Endoscopic Brow Lift", costPLN: [24000, Infinity]},
					{name: "Endoscopic Temporal Brow Lift", costPLN: [20000, Infinity]},
					{name: "Endoscopic Brow Lift + Midface Lift", costPLN: [30000, Infinity]},
					{name: "Isolated Deep Neck Lift (Chin Contouring)", costPLN: [24000, Infinity]},
					{name: "Neck Lift with incision behind the ear", costPLN: [30000, Infinity]},
					{name: "Lip Lift", costPLN: [8000, Infinity]},
					{name: "Cupid Lift (Lip Lift + Corner Lift)", costPLN: [10000, Infinity]},
					{name: "Upper and lower eyelid surgery", costPLN: [16000, 17000]},
					{name: "Lower eyelid surgery", costPLN: [11000, 12000]},
					{name: "Secondary lower eyelid correction", costPLN: [13500, 15000]},
					{name: "Upper eyelid surgery", costPLN: [7000, 8000]},
					{name: "Secondary upper eyelid correction", costPLN: [10000, 12000]},
				]
			},
			{
				name: "Nose",
				content: [
					{name: "Complete Rhinoplasty", costPLN: [26000, Infinity]},
					{name: "Secondary Rhinoplasty", costPLN: [28000, Infinity]},
				]
			},
			{
				name: "Ears",
				content: [
					{name: "Otoplasty (Ear Pinning)", costPLN: [8000, Infinity]},
					{name: "Ear Reduction", costPLN: [9500, Infinity]},
					{name: "Ear Reconstruction", costPLN: [16000, Infinity]},
					{name: "Ear Repositioning", costPLN: [7000, Infinity]},
				]
			},
			{
				name: "Facial Fat Grafting",
				content: [
					{name: "Fat Transfer (cheeks, nasolabial folds, temples, lower eyelids)", costPLN: [8000, Infinity]},
					{name: "Fat Transfer with Stem Cells", costPLN: [12000, Infinity]},
				]
			},
			{
				name: "Chin",
				content: [
					{name: "Facial Implant", costPLN: [16000, Infinity]},
					{name: "Chin Reduction", costPLN: [18000, Infinity]},
				]
			},
		]
	},
	{
		name: "Facial feminization - maxillofacial surgery",
		content: [
			{
				name: "",
				content: [
					{name: "Bichectomy", costPLN: [10000, Infinity]},
					{name: "Secondary Bichectomy", costPLN: [14000, Infinity]},
					{name: "Surgical Treatment of Gummy Smile", costPLN: [9000, Infinity]},
					{name: "Augmentation of Cheekbones with Implants", costPLN: [16000, Infinity, ['implant cost']]},
					{name: "Chin Surgery (Genioplasty)", costPLN: [17000, Infinity]},
					{name: "T-Type Chin Surgery", costPLN: [20000, Infinity]},
					{name: "T/Wing Chin Surgery / Asymmetry Correction", costPLN: [22000, Infinity]},
					{name: "Jaw Angle Reduction", costPLN: [27000, Infinity]},
					{name: "Comprehensive Jaw Feminization", costPLN: [30000, Infinity]},
					{name: "Chin Implant", costPLN: [16000, Infinity, ['implant cost']]},
					{name: "Jaw Angle Implants", costPLN: [28000, Infinity, ['implant cost']]},
					{name: "Thyroid Cartilage Reduction (Adam’s Apple Reduction)", costPLN: [20000, Infinity]},
				]
			},
		]
	},
	{
		name: "Body feminization",
		content: [
			{
				name: "Breasts",
				content: [
					{name: "Fat Transfer (Own Fat Grafting)", costPLN: [20000, Infinity]},
					{name: "Mastopexy (Breast Lift)", costPLN: [26000, Infinity]},
					{name: "Breast Lift with Implants", costPLN: [30000, Infinity]},
					{name: "Breast Lift with Implants and GalaFlex Mesh", costPLN: [35000, Infinity]},
					{name: "Breast Augmentation with Implants", costPLN: [26000, Infinity]},
					{name: "Implant Replacement", costPLN: [27000, Infinity]},
					{name: "Breast Reduction", costPLN: [26000, Infinity]},
					{name: "Implant Removal", costPLN: [20000, Infinity]},
				]
			},
		]
	},
	{
		name: "Body feminization - body contouring",
		content: [
			{
				name: "",
				content: [
					{name: "Abdominoplasty (Tummy Tuck)", costPLN: [26000, Infinity]},
					{name: "Secondary Abdominoplasty", costPLN: [30000, Infinity]},
					{name: "Abdominoplasty with Liposuction", costPLN: [30000, Infinity]},
					{name: "Abdominoplasty after Massive Weight Loss", costPLN: [30000, Infinity]},
					{name: "Mini-Abdominoplasty", costPLN: [20000, Infinity]},
					{name: "Abdominoplasty with 360° Liposuction", costPLN: [40000, Infinity]},
					{name: "Abdominoplasty with 4D Liposuction (Dynamic Definition)", costPLN: [40000, Infinity]},
				]
			},
			{
				name: "VASER Lipo – Ultrasound Liposuction",
				content: [
					{name: "Hips", costPLN: [15000, Infinity]},
					{name: "Abdomen", costPLN: [16000, Infinity]},
					{name: "Abdomen with Abdominoplasty", costPLN: [30000, Infinity]},
					{name: "Abdomen and Hips", costPLN: [24000, Infinity]},
					{name: "Abdomen and Hips – High Definition (6-pack)", costPLN: [30000, Infinity]},
					{name: "Abdomen and Hips – High Definition (6-pack) + ARGO PLASMA", costPLN: [36000, Infinity]},
					{name: "Chest (Men)", costPLN: [18000, Infinity]},
					{name: "Chin", costPLN: [14000, Infinity]},
					{name: "Chin + ARGO PLASMA", costPLN: [20000, Infinity]},
					{name: "Arms", costPLN: [16000, Infinity]},
					{name: "Thighs", costPLN: [16000, Infinity]},
					{name: "Thighs + Knees", costPLN: [20000, Infinity]},
				]
			},
			{
				name: "Fat Transfer – Fat Grafting",
				content: [
					{name: "Brazilian Butt Lift (BBL) – Fat Transfer to Buttocks + Liposuction of Back, Hips, Thighs", costPLN: [35000, Infinity]},
					{name: "Fat Transfer to Buttocks", costPLN: [24000, Infinity]},
				]
			},
			{
				name: "Body Lifting",
				content: [
					{name: "Arm Lift", costPLN: [20000, Infinity]},
					{name: "Thigh Lift", costPLN: [25000, Infinity]},
					{name: "Lower Body Lift (Abdominoplasty + Buttock and Lateral Thigh Lift)", costPLN: [50000, Infinity]},
					{name: "Upper Body Lift (Breast/Upper Back Lift)", costPLN: [40000, Infinity]},
				]
			},
		]
	},
	{
		name: "Body masculinization",
		content: [
			{
				name: "",
				content: [
					{name: "Breast removal (mastectomy)", costPLN: [18000, Infinity]},
					{name: "Body masculinization", costPLN: [17000, Infinity]},
				]
			},
		]
	},
	{
		name: "Hair transplantation",
		content: [
			{
				name: "",
				content: [
					{name: "Hairline Lowering / Receding Hairline Correction", costPLN: [16500, Infinity]},
					{name: "1 FUE, 1,000 grafts", costPLN: [17000, 17000]},
					{name: "1 FUE, 2,000 grafts", costPLN: [28000, 28000]},
					{name: "1 FUE, 3,000 grafts", costPLN: [36000, 36000]},
				]
			},
		]
	},
	{
		name: "Laser therapy",
		content: [
			{
				name: "",
				content: [
					{name: "Laser Skin Resurfacing", costPLN: [2500, Infinity]},
					{name: "Scar Removal with Laser", costPLN: [1200, Infinity]},
					{name: "Stretch Mark Reduction", costPLN: [1500, Infinity]},
					{name: "Vascular Lesion Treatment", costPLN: [1500, Infinity]},
					{name: "Pigmentation Treatment", costPLN: [2500, Infinity]},
				]
			},
		]
	},
	{
		name: "Assistance",
		content: [
			{
				name: "",
				content: [
					{name: "Taxi airport <=> hotel", costPLN: [125, 125]},
					{name: "Hotel for 7 nights", costPLN: [2340, 2340]},
				]
			},
		]
	},
]

const WHEN_OPTIONS = [
	"This year",
	"Next year",
	"Even later",
	"I don`t know yet",
]

const DEFAULT_INTEREST: UserSurgeryInterestContent = {
	procedures: new Set('Hotel for 7 nights'),
	totalPrice: '',
	when: 'Even later',
}

function formatPriceRange([min, max, plus]: PriceRange) {
	return (
		min === max ? '' :
		max === Infinity ? 'at least ' :
		''
	) +
	'€' + Math.round(min / PLNperEUR).toLocaleString() +
	(
		min === max ? '' :
		max === Infinity ? '' :
		' - €' + Math.round(max / PLNperEUR).toLocaleString()
	) +
	(plus?.length ? ' + ' + plus.join(', ') : '')
}

const PROCEDURES_FLAT = PROCEDURES.flatMap(group => group.content.flatMap(subgroup => subgroup.content))

export function Surgeries({fullScreen}: {fullScreen?: boolean}) {
	const interest = useSurgeryInterest()
	const setInterest = useSetSurgeryInterest()
	const [setInterest_changed, setInterest_setChanged] = useState(false)
	const [setInterest_isPending, setInterest_setIsPending] = useState(false)
	const submitSurgeryRequest = useSubmitSurgeryRequest()
	const [submitSurgeryRequest_done, submitSurgeryRequest_setDone] = useState(false)
	const [submitSurgeryRequest_isPending, submitSurgeryRequest_setIsPending] = useState(false)

	const Section = fullScreen ? 'main' : 'div'
	const H = fullScreen ? 'h1' : 'h2'
	const user = useProfile()!

	const defaultInterest: UserSurgeryInterestContent = interest ?? DEFAULT_INTEREST
	const [currentProcedures, setCurrentProcedures] = useState(defaultInterest.procedures)
	const [currentWhen, setCurrentWhen] = useState(defaultInterest.when)
	const currentTotal = PROCEDURES_FLAT.filter(p => currentProcedures.has(p.name)).map(p => p.costPLN).reduce((acc, v) => [acc[0] + v[0], acc[1] + v[1], [...(acc[2] ?? []), ...(v[2] ?? [])]], [0, 0])

	if (interest === undefined) return

	return <Section>
		{fullScreen ? <Hello/> : <></>}
		<section>
			<h1>Gender-affirming surgeries</h1>
			<p>We understand that planning gender-affirming surgery can feel overwhelming, and we’re here to make the process as clear and reassuring as possible. Our Surgery Calculator is designed to give you a realistic idea of costs (all prices are listed as "starting from", as the final cost always depends on individual evaluation).</p>
			<p>If you're unsure exactly what procedures you need - or if you're concerned about making the "wrong" choice - don’t worry! Many people start with a general idea and refine their plan through expert guidance. This tool is here to help you explore, not to pressure you into a decision. Remember, your choices are not binding.</p>
			<p>Once you’ve reviewed your estimate, you can save it for later or send a request to book a consultation, where specialists will help tailor everything to your unique goals. Take your time, explore your options, and know that you’re in good hands every step of the way. </p>
		</section>
		<hr/>
		<section>
			<h2>Cost calculator</h2>
			<form onChange={ev => {
				const data = new FormData(ev.currentTarget)
				setCurrentProcedures(new Set([...data.keys()].filter(k => k !== 'when')))
				setCurrentWhen(data.get('when') as string)
				setInterest_setChanged(true)
			}} onSubmit={ev => {
				ev.preventDefault()
				ev.stopPropagation()
				setInterest_setIsPending(true)
				setInterest.mutate({
					procedures: currentProcedures,
					when: currentWhen,
					totalPrice: formatPriceRange(currentTotal),
				}, {
					onSuccess: () => setInterest_setChanged(false),
					onSettled: () => setInterest_setIsPending(false),
				})
			}}>
			<fieldset>
				<hgroup>
					<h4>Which procedures am I interested in?</h4>
				</hgroup>
				{PROCEDURES.map(group => <>
					<h5>{group.name}</h5>
					{group.content.map(subgroup => <>
						{subgroup.name ? <h6>{subgroup.name}</h6> : <></>}
						<table>
							<tbody>
								{subgroup.content.map(proc => <tr>
									<td style={{flex: '0 auto'}}>
										<input type="checkbox" id={proc.name} name={proc.name} defaultChecked={defaultInterest.procedures.has(proc.name)}/>
									</td>
									<td><label htmlFor={proc.name}>{proc.name}</label></td>
									<td style={{flex: '0 auto', textAlign: 'right'}}>
										{formatPriceRange(proc.costPLN)}
									</td>
								</tr>)}
							</tbody>
						</table>
					</>)}
				</>)}
				<h5 style={{display: 'flex', flexFlow: 'row'}}>
					<span style={{flex: '1'}}>Total</span>
					<span>{currentTotal[0] ? formatPriceRange(currentTotal) : <></>}</span>
				</h5>
			</fieldset>
			<fieldset>
				<hgroup>
					<h4>When do I want to get the procedures done?</h4>
				</hgroup>
				{WHEN_OPTIONS.map(option =>
					<label>
						<input type="radio" name="when" value={option} defaultChecked={defaultInterest.when === option}/> {option}
					</label>
				)}
			</fieldset>
			<button type="submit" disabled={setInterest_isPending || !setInterest_changed}>{setInterest_isPending ? "Saving..." : setInterest_changed ? "Save" : "Saved."}</button>
			</form>
		</section>
		<hr/>
		<section>
			<h2>Book a surgery</h2>
			<form onSubmit={ev => {
				ev.preventDefault()
				ev.stopPropagation()
				const data = new FormData(ev.currentTarget)
				submitSurgeryRequest_setIsPending(true)
				submitSurgeryRequest.mutate({
					quote: {
						procedures: currentProcedures,
						when: currentWhen,
						totalPrice: formatPriceRange(currentTotal),
					},
					comments: (data.get('comments') ?? '') as string,
					patient: {
						shortName: (data.get('shortName') ?? '') as string,
						pronouns: (data.get('pronouns') ?? '') as string,
						email: (data.get('email') ?? '') as string,
						phone: (data.get('phone') ?? '') as string,
					}
				}, {
					onSuccess: () => submitSurgeryRequest_setDone(true),
					onSettled: () => submitSurgeryRequest_setIsPending(false),
				})
			}}>
			<fieldset>
				<hgroup>
					<h4>Your details</h4>
				</hgroup>
				<div id="your-details">
					<label>
						<span>Your chosen first name</span>
						<input type="text" name="shortName" value={user.shortName} required/>
					</label>
					<label>
						<span>Your pronouns</span>
						<input type="text" name="pronouns" value={user.pronouns} required/>
					</label>
					<label>
						<span>Your email</span>
						<input type="email" name="email" required/>
					</label>
					<label>
						<span>Your phone number</span>
						<input type="tel" name="phone"/>
					</label>
				</div>
			</fieldset>
			<fieldset>
				<label>
					<span>Any comments?</span>
					<textarea></textarea>
				</label>
			</fieldset>
			<button type="submit" disabled={submitSurgeryRequest_isPending || submitSurgeryRequest_done}>{submitSurgeryRequest_isPending ? "Sending..." : submitSurgeryRequest_done ? "Sent." : "Send a request to Hospittal"}</button>
			<p>Hospittal will respond within 7 days from receiving your application. Your personal concierge will be appointed at this stage to support you during the whole process.</p>
			</form>
		</section>
	</Section>
}

import { Welcome } from "./Welcome.tsx"
import { PrepareYourGAHT } from "./Prepare.tsx"
import { ReadyToGAHT } from "./Ready.tsx"
import { useGAHTSetupAppointment } from "./tasks/TalkWithAPhysician.tsx"
import { useOwns, useWelcomed } from "../../api.ts"
import { GAHTOnboarding_ID } from "@imago/model"
import { Temporal } from "@imago/api-client"
import { ProvGAHT } from "./ProvGAHT.tsx"

export function GAHT({fullScreen}: {fullScreen?: boolean}) {
	const welcomed = useWelcomed()!
	const hasGAHTOnboarding = useOwns(GAHTOnboarding_ID)
	const setupAppointment = useGAHTSetupAppointment()
	const setupAppointmentPassed = setupAppointment ? Temporal.Instant.compare(setupAppointment.end.toInstant(), Temporal.Now.instant()) < 0 : false

	const setupAppointmentWasALongTimeAgo = setupAppointment ? Temporal.Instant.compare(setupAppointment.end.toInstant(), Temporal.Now.instant().subtract({hours: 40*24})) < 0 : false

	const onGAHT = setupAppointmentWasALongTimeAgo // TODO actually activate this after we send out the first prescription

	const gahtOnboarding =
		onGAHT ? "review-gaht" :
		setupAppointmentPassed ? "ready-to-gaht" :
		(hasGAHTOnboarding || welcomed) ? "prepare-your-gaht" :
		"welcome"

	return gahtOnboarding === 'welcome' ? <>
		<Welcome fullScreen={fullScreen}/>
	</> : gahtOnboarding === 'prepare-your-gaht' ? <>
		<PrepareYourGAHT fullScreen={fullScreen}/>
	</> : gahtOnboarding === 'ready-to-gaht' ? <>
		<ReadyToGAHT fullScreen={fullScreen}/>
	</> : gahtOnboarding === 'review-gaht' ? <>
		<ProvGAHT fullScreen={fullScreen}/>
	</> : (gahtOnboarding satisfies never)
}
